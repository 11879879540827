<template>
  <div class="edit-employee">
    <Breadcrumbs
      :views="[{ label: 'Empleados', to: '/personas-y-puestos/empleados' }]"
      :currentView="{ label: 'Nuevo Empleado' }"
    />
    <h2>Nuevo Empleado</h2>
    <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
      <form @submit.prevent="handleSubmit(add)">
        <div>
          <div class="form__section personal-info">
            <h4>Información Personal</h4>
            <div>
              <custom-input
                v-model="employee.name"
                label="Nombre"
                type="text"
                rules="required"
                icon="user"
              />
              <custom-input
                v-model="employee.email"
                label="Email"
                type="email"
                rules="email"
                icon="envelope"
              />
              <custom-select
                v-model="employee.gender"
                :options="[
                  {
                    name: 'Masculino',
                    id: 'Masculino',
                  },
                  {
                    name: 'Femenino',
                    id: 'Femenino',
                  },
                ]"
                label="Género"
                rules="required"
                icon="circle"
              />
              <custom-input
                v-model="employee.documentNumber"
                label="Número de documento"
                type="text"
                rules="required"
                icon="id"
              />
              <custom-select
                v-model="employee.documentType"
                :options="[
                  {
                    name: 'DNI',
                    id: 'DNI',
                  },
                  {
                    name: 'Pasaporte',
                    id: 'Pasaporte',
                  },
                  {
                    name: 'Carné de Extranjería',
                    id: 'Carné de Extranjería',
                  },
                  {
                    name: 'Otro',
                    id: 'Otro',
                  },
                ]"
                label="Tipo de documento"
                rules="required"
                icon="id"
              />
              <custom-input
                v-model="employee.birthDate"
                label="Fecha de nacimiento"
                type="date"
                rules="required"
                icon="calender"
              />
              <custom-input
                v-model="employee.entryDate"
                label="Fecha de ingreso"
                type="date"
                :rules="`required|min_date:${employee.birthDate}`"
                icon="calender"
              />
              <custom-input
                v-model="employee.terminationDate"
                label="Fecha de cese"
                type="date"
                :rules="`min_date:${employee.entryDate}`"
                icon="calender"
              />
            </div>
          </div>
          <div class="form__section remuneration">
            <h4>Remuneración</h4>
            <div>
              <div>
                <span>Sueldo Básico</span>
                <custom-input
                  v-model="employee.miniumWage"
                  type="number"
                  min="0"
                  rules="required|positive"
                  icon="money-bill"
                />
              </div>
              <div class="otherIncomes">
                <span>
                  Otros Ingresos
                  <unicon
                    @click="employee.otherIncomes.push(0)"
                    name="plus-circle"
                    fill="currentColor"
                    height="15.5px"
                    width="15.5px"
                  ></unicon>
                </span>
                <div>
                  <span v-for="(income, index) in employee.otherIncomes" :key="index">
                    <custom-input
                      min="0"
                      type="number"
                      v-model="employee.otherIncomes[index]"
                      rules="required|positive"
                      icon="money-bill"
                    />
                    <unicon
                      @click="
                        employee.otherIncomes.length > 1
                          ? employee.otherIncomes.splice(index, 1)
                          : null
                      "
                      name="minus-circle"
                      fill="currentColor"
                      height="15.5px"
                      width="15.5px"
                    ></unicon>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form__section indicators">
            <h4>Categorías</h4>
            <div>
              <custom-select
                v-for="category in categories.filter((category) => !category.isDeletable)"
                v-model="employee[category.id]"
                :key="category.id"
                :options="options.filter((option) => option.categoryId === category.id)"
                :label="category.name"
                rules="required"
                icon="folder"
              />
            </div>

            <span class="separator"></span>

            <div>
              <custom-select
                v-for="category in categories.filter((category) => category.isDeletable)"
                v-model="employee[category.id]"
                :key="category.id"
                :options="options.filter((option) => option.categoryId === category.id)"
                :label="category.name"
                rules="required"
                icon="folder-plus"
              />
            </div>
          </div>
        </div>
        <div class="form__buttons">
          <Button
            type="submit"
            :disabled="invalid || isAddingLoading"
            size="medium"
            variant="primary"
          >
            {{ isAddingLoading ? 'Agregando...' : 'Agregar empleado' }}</Button
          >
          <Button
            to="/personas-y-puestos/empleados"
            :disabled="invalid || isAddingLoading"
            size="medium"
            variant="text"
            >Cancelar</Button
          >
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Button from '@/components/buttons/Button.vue';
import {
  timeStampToYMD, YMDToDate, YMDToDMY, YMDToTimeStamp,
} from '@/dateFormats';

export default {
  name: 'AddEmployee',
  components: {
    ValidationObserver,
    CustomInput,
    CustomSelect,
    Button,
    Breadcrumbs,
  },
  data() {
    return {
      employee: {
        documentType: 'DNI',
        gender: 'Masculino',
        miniumWage: 0,
        otherIncomes: [0],
      },

      isAddingLoading: false,
      timeStampToYMD,
      YMDToDate,
      YMDToDMY,
      YMDToTimeStamp,
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('employees', ['addEmployee']),
    ...mapActions('history', ['addLog']),
    async add() {
      try {
        this.isAddingLoading = true;
        await this.addEmployee({
          ...this.employee,
          birthDate: this.YMDToDate(this.employee.birthDate),
          entryDate: this.YMDToDate(this.employee.entryDate),
          terminationDate: this.employee.terminationDate
            ? this.YMDToDate(this.employee.terminationDate)
            : null,
          history: this.getEmployeeHistory(),
        });
        await this.addHistory();
        this.setAlert({
          state: 'success',
          message: 'Empleado agregado',
        });
      } catch (error) {
        console.log(error);
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      } finally {
        this.isAddingLoading = false;
        this.$router.push('/personas-y-puestos/empleados');
      }
    },

    getEmployeeHistory() {
      const employeeHistory = {};
      Object.keys({
        ...this.employee,
      }).forEach((key) => {
        const employee = {
          ...this.employee,
          birthDate: this.YMDToDate(this.employee.birthDate),
          entryDate: this.YMDToDate(this.employee.entryDate),
          terminationDate: this.employee.terminationDate
            ? this.YMDToDate(this.employee.terminationDate)
            : null,
        };
        employeeHistory[key] = {};
        employeeHistory[key][this.YMDToTimeStamp(this.employee.entryDate).seconds] = employee[key];
      });
      return employeeHistory;
    },

    async addHistory() {
      let content = `Nombre: ${this.employee.name}\nEmail: ${
        this.employee.email || 'No ingresado'
      }\nGénero: ${this.employee.gender}\nNúmero de documento: ${
        this.employee.documentNumber
      }\nTipo de documento: ${this.employee.documentType}\nFecha de nacimiento: ${this.YMDToDMY(
        this.employee.birthDate,
      )}\nFecha de ingreso: ${this.YMDToDMY(
        this.employee.entryDate,
      )}\nFecha de cese: ${this.YMDToDMY(this.employee.terminationDate)}\nSueldo Básico: ${
        this.employee.miniumWage
      }\nOtros Ingresos: ${this.employee.otherIncomes.reduce((acc, val) => acc + val, 0)}\n`;
      this.categories.forEach((categoy) => {
        content += `${categoy.name}: ${
          this.options.find((option) => option.id === this.employee[categoy.id]).name
        }\n`;
      });
      await this.addLog({
        authorId: this.userProfile.id,
        createdOn: new Date(),
        authorName: this.userProfile.name,
        action: 'Agregar',
        content: `Nuevo Empleado:\n${content}`,
      });
    },
  },

  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.edit-employee {
  height: 100%;
  display: flex;
  flex-flow: column;
  h2 {
    margin-bottom: 0.15em;
  }

  form {
    height: 100%;
    display: flex;
    flex-flow: column;
    & > div {
      margin-top: 1em;
    }

    & > div:first-child {
      overflow: auto;
    }

    button {
    }
  }

  .form__validation {
    overflow: hidden;
  }

  .form__section {
    width: 100%;
    & > div {
      padding: 1rem 0;
      gap: 1rem;
      display: grid;
    }

    &.personal-info {
      grid-area: personal-info;
      & > div {
        grid-template-columns: repeat(3, auto);
        padding-right: .5rem;
      }
    }

    &.indicators {
      grid-area: indicators;
      display: flex;
      flex-flow: column;
      & > div {
        grid-template-columns: repeat(3, auto);
        padding-right: .5rem;
      }
    }

    &.remuneration {
      grid-area: remuneration;
      .input {
        width: 8rem;
      }
      .unicon {
        display: flex !important;
        align-items: center;
        cursor: pointer;
      }

      & > div {
        display: flex;
        div > span {
          display: flex;
          gap: 0.5em;
          color: var(--font-color-300);
          font-size: 0.875rem;
          font-weight: var(--medium);
          margin-bottom: 0.5em;
          .unicon {
            color: var(--main-color-500);
          }
        }
      }

      .otherIncomes {
        width: 88%;
        & > div {
          overflow-x: auto;
          display: flex;
          flex-wrap: nowrap;
          gap: 1em;

          span {
            display: flex;
            gap: 0.5em;
            .unicon {
              color: var(--danger-color-500);
            }
          }
          .input {
            min-width: 5em;
          }
        }
      }
    }
  }

  .form__buttons {
    display: flex;
    margin-top: 0;
    padding-top: 1rem;
    border-top: solid 2px var(--gray-color-200);
  }
}

.separator {
  background-color: var(--gray-color-200);
  height: 1px;
}
</style>
