<template>
  <ValidationProvider
    tag="label"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="input"
    :vid="vid"
  >
    <input
      :value="value"
      :type="type"
      :min="min"
      :step="step"
      :placeholder="placeholder || placeholderTemp"
      autocomplete="on"
      :disabled="disabled"
      @input="
        $emit('input', type === 'number' ? parseInt($event.target.value) : $event.target.value)
      "
      class="field"
      :class="{
        'field--invalid': errors.length,
        'input--shadow': shadow,
        'field--haveIcon': !!icon,
      }"
    />

    <figure v-if="icon" :class="{ 'figure--invalid': errors.length }">
      <font-awesome-icon v-if="['id'].includes(icon)" :icon="icons[icon]" />
      <unicon v-else :name="icon" fill="currentColor" height="15.5px" width="15.5px"></unicon>
    </figure>

    <span
      v-if="label"
      class="label"
      :class="{ 'label--invalid': errors.length, 'label--haveIcon': !!icon }"
    >
      {{ `${label || name} ${required ? '*' : ''}` }}
    </span>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';

export default {
  name: 'CustomInput',

  components: { ValidationProvider },

  data() {
    return {
      icons: {
        id: faAddressCard,
      },
    };
  },

  props: {
    value: {
      default: '',
    },

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    shadow: {
      type: Boolean,
      default: false,
    },

    step: {
      type: Number,
      default: 1,
    },

    icon: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    vid: String,

    min: String,

    label: String,
    placeholder: String,
  },

  computed: {
    placeholderTemp() {
      switch (this.type) {
        case 'email':
          return 'ejemplo@mail.com';
        case 'password':
          return 'por lo menos 6 caracteres';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
}

input:disabled,
input:disabled + figure {
  /* styles for disabled input */
  opacity: 0.5;
  cursor: not-allowed;
}

input::placeholder {
  font-size: 0.875rem;
  font-weight: var(--light);
}

figure {
  height: 43.55px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  background-color: var(--gray-color-100);
  border-top-left-radius: 3.5px;
  border-bottom-left-radius: 3.5px;
  border: 1px solid var(--gray-color-900);
  transition: border-color 300ms;
  .unicon {
    color: var(--font-color-500);
  }
  svg {
    color: var(--font-color-500);
    font-size: 15.5px;
    width: 15.5px !important;
  }
}

.figure--invalid {
  border-color: var(--danger-color-500);
  .unicon {
    color: var(--danger-color-500);
  }
  svg {
    color: var(--danger-color-500);
  }
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: calc(0.625rem);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
  color: var(--font-color-500);

  &--haveIcon {
    left: calc(3.47rem - 4px);
  }
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--main-color-500);
}

.label--invalid {
  color: var(--danger-color-500);
}

.field {
  max-width: 100%;
  width: 100%;
  padding: 0.755rem calc(0.625rem + 4px) 0.655rem;
  color: var(--font-color-700);
  border: 1px solid var(--gray-color-900);
  border-radius: 3.5px;
  transition: border-color 300ms;
  height: 43.55px;

  &--haveIcon {
    padding-left: 3.47rem;
  }
}

.field:not(.field--invalid):focus {
  border-color: var(--main-color-500);

  & + figure {
    border-color: var(--main-color-500);
    .unicon {
      color: var(--main-color-500);
    }
    svg {
      color: var(--main-color-500);
    }
  }
}

.field--invalid {
  color: var(--danger-color-500);
  border-color: var(--danger-color-500);
}

.input--shadow:hover {
  background-color: var(--gray-color-100);
}

.input--shadow.field:not(.field--invalid) {
  border-color: var(--gray-color-100);
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}

.input--shadow.field:not(.field--invalid):focus {
  border-color: var(--gray-color-100);
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}
</style>
