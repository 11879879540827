<template>
  <div class="breadcrumbs">
    <template v-for="view in views">
      <router-link class="router-link" :key="`${view.label}-label`" :to="view.to">
        <unicon
          v-if="view.icon"
          :name="view.icon"
          height="20"
          width="20"
          fill="currentColor"
        ></unicon>
        <p>{{ view.label }}</p>
      </router-link>
      <span :key="`${view.label}-span`">
        <unicon name="angle-right" height="20" width="20"></unicon>
      </span>
    </template>

    <p>
      <unicon
        v-if="currentView.icon"
        :name="currentView.icon"
        height="16"
        width="16"
        fill="currentColor"
      ></unicon>
      {{ currentView.label }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'Breadcrumbs',

  components: {},

  data: () => ({
  }),

  props: {
    views: {
      type: Array,
    },
    currentView: {
      type: Object,
    },
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  .router-link {
    cursor: pointer;
    &:hover {
      text-decoration: underline var(--main-color-500);
    }
  }
  a {
    display: flex;
    gap: 0.5rem;
  }
  span {
    fill: var(--main-color-500);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: 0.83em;
    color: var(--main-color-500);
    letter-spacing: 0.4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.6rem;
  }
  & > p:last-child {
    color: var(--font-color-600);
  }
}
</style>
