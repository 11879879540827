import { render, staticRenderFns } from "./CustomSelect.vue?vue&type=template&id=e1cb1452&scoped=true&"
import script from "./CustomSelect.vue?vue&type=script&lang=js&"
export * from "./CustomSelect.vue?vue&type=script&lang=js&"
import style0 from "./CustomSelect.vue?vue&type=style&index=0&id=e1cb1452&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1cb1452",
  null
  
)

export default component.exports