<template>
  <ValidationProvider
    tag="label"
    :rules="!options.length ? '' : rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="select"
    :class="size"
  >
    <select
      @input="$emit('change', $event.target.value)"
      class="field"
      :class="{
        'field--haveIcon': !!icon,
        'field--invalid': errors.length,
      }"
      :tabindex="tabindex"
      :disabled="!options.length"
      :value="selected || ''"
    >
      <option disabled :value="''" :style="optionStyles">Elija una opción</option>

      <option
        v-for="option of options"
        :key="option.id || option.value"
        :value="typeof option === 'object' ? option.id || option.value : option"
        :style="optionStyles"
      >
        {{ typeof option === 'object' ? option.value || option.name : option }}
      </option>
    </select>

    <figure v-if="icon" :class="{ 'figure--invalid': errors.length }">
      <font-awesome-icon v-if="['id'].includes(icon)" :icon="icons[icon]" />
      <unicon v-else :name="icon" fill="currentColor" height="15.5px" width="15.5px"></unicon>
    </figure>

    <span
      v-if="label"
      class="label"
      :class="{ 'label--invalid': errors.length, 'label--haveIcon': !!icon }"
    >
      <span>{{ label || name }}</span>
      <span>{{ required ? ' *' : '' }}</span>
    </span>

    <span v-if="errors.length" class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';

export default {
  name: 'CustomSelect',

  model: {
    prop: 'selected',
    event: 'change',
  },

  components: { ValidationProvider },

  data() {
    return {
      icons: {
        id: faAddressCard,
      },
    };
  },

  props: {
    selected: {
      default: '',
      type: String,
    },

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      type: String,
      default: '',
    },

    label: String,

    options: {
      type: Array,
      required: true,
    },

    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },

    icon: {
      type: String,
      default: '',
    },

    size: {
      type: String,
    },
  },

  watch: {
    options(value) {
      const opts = typeof value[0] === 'object'
        ? value.reduce((a, o) => a.concat([o.id || o.value]), [])
        : value;
      if (!opts.includes(this.selected)) this.$emit('change', '');
    },
  },

  computed: {
    optionStyles: () => ({
      color: 'var(--font-color-600)',
      fontFamily: 'var(--inter)',
    }),
  },
};
</script>

<style lang="scss" scoped>
.select {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

select {
  background-color: white;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: calc(0.625rem);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
  color: var(--font-color-500);

  &--haveIcon {
    left: calc(3.47rem);
  }
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--main-color-500);
}

.label--invalid,
.error {
  color: var(--danger-color-500);
}

.error {
  margin-top: 0.375rem;
  font-size: 0.75rem;
}

.field {
  width: 100%;
  padding: 0.755rem 0.625rem 0.655rem;
  color: var(--font-color-700);
  border: 1px solid var(--gray-color-900);
  border-radius: 3.5px;
  cursor: pointer;
  transition: border-color 300ms;
  height: 43.55px;

  &--haveIcon {
    padding-left: 3.47rem;
  }
}

.field:not(.field--invalid):focus {
  border-color: var(--main-color-500);

  & + figure {
    border-color: var(--main-color-500);
    .unicon {
      color: var(--main-color-500);
    }
    svg {
      color: var(--main-color-500);
    }
  }
}

.field--invalid {
  border-color: var(--danger-color-500);
}

figure {
  height: 43.55px;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0.875rem;
  background-color: var(--gray-color-100);
  border-top-left-radius: 3.5px;
  border-bottom-left-radius: 3.5px;
  border: 1px solid var(--gray-color-900);
  transition: border-color 300ms;
  .unicon {
    color: var(--font-color-500);
  }
  svg {
    color: var(--font-color-500);
    font-size: 15.5px;
    width: 15.5px !important;
  }
}

.figure--invalid {
  border-color: var(--danger-color-500);
  .unicon {
    color: var(--danger-color-500);
  }
  svg {
    color: var(--danger-color-500);
  }
}

.small {
  .field {
    height: 35px;
    padding: 0.53rem 0.5rem 0.52rem;
    font-size: 0.825rem;
  }
}
</style>
