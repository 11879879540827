var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"input",attrs:{"tag":"label","rules":_vm.rules,"name":_vm.name || _vm.label,"vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('input',{staticClass:"field",class:{
      'field--invalid': errors.length,
      'input--shadow': _vm.shadow,
      'field--haveIcon': !!_vm.icon,
    },attrs:{"type":_vm.type,"min":_vm.min,"step":_vm.step,"placeholder":_vm.placeholder || _vm.placeholderTemp,"autocomplete":"on","disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.$emit('input', _vm.type === 'number' ? parseInt($event.target.value) : $event.target.value)}}}),(_vm.icon)?_c('figure',{class:{ 'figure--invalid': errors.length }},[(['id'].includes(_vm.icon))?_c('font-awesome-icon',{attrs:{"icon":_vm.icons[_vm.icon]}}):_c('unicon',{attrs:{"name":_vm.icon,"fill":"currentColor","height":"15.5px","width":"15.5px"}})],1):_vm._e(),(_vm.label)?_c('span',{staticClass:"label",class:{ 'label--invalid': errors.length, 'label--haveIcon': !!_vm.icon }},[_vm._v(" "+_vm._s(((_vm.label || _vm.name) + " " + (required ? '*' : '')))+" ")]):_vm._e(),(errors.length)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }